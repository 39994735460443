<script>
import GlobalVue from '@helper/Global.vue';
import BOGen from '@helper/BOGen';

export default {
  extends: GlobalVue,
  data(){
    return {
      havePermission: true,
      mrValidation: {}
    }
  },
  computed:{
    type(){
      var inputName = this.$route.query.CKEditor
      if(inputName=="template") return "static_content";
      if(inputName=="pp_content") return "blog";
      if(inputName=="ap_desc") return "blog";
      if(inputName=="apb_desc_id") return "blog";
      if(inputName=="apb_desc_en") return "blog";
      return ""
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
    if(BOGen.notLogin()){
      return this.havePermission = false
    }
    this.refreshVariable()
  }
}
</script>

<template>
  <div>
    <div v-if="havePermission">
      <FileManager inline :type="type"></FileManager>
      <ImageFilter></ImageFilter>
    </div>
    <h3 class="tc" v-else>You don't have permission</h3>
  </div>
</template>
